const BASE_URL = 'https://withered-feather-36062.botics.co/'
// const BASE_URL = 'http://0.0.0.0:8000/'

export const defaultConfig = {
  API_URL: BASE_URL
}

export const App = {
  config: defaultConfig
}

window.env = window.env || defaultConfig
App.config = { ...window.env }

export const API_URL = () => App.config.API_URL
