import {API} from './'

export const getDashboard = token => {
  return API.get(`api/v1/admin/dashboard/`, token)
}

export const getDashboardMeetings = token => {
  return API.get(`api/v1/admin/meeting-dashboard/`, token)
}

export const getAllUsers = (payload, token) => {
  return API.get(`api/v1/admin/users/${payload}`, token)
}

export const getPayments = token => {
  return API.get(`api/v1/admin/payment/`, token)
}

export const getPaymentTotal = token => {
  return API.get(`api/v1/admin/payment/total/`, token)
}

export const updateZipcodes = (id, payload, token) => {
  return API.patch(`api/v1/admin/zipcodes/${id}/`, payload, token)
}

export const updateUser = (id, payload, token) => {
  return API.patch(`api/v1/admin/users/${id}/`, payload, token)
}

export const banUser = (id, token) => {
  return API.post(`api/v1/admin/users/${id}/ban/`, {}, token)
}

export const deleteOrder = (id, token) => {
  return API.delete(`api/v1/admin/orders/${id}/`, {}, token)
}

export const deleteUser = (id, token) => {
  return API.delete(`api/v1/admin/users/${id}/`, {}, token)
}

export const addZipcodes = (payload, token) => {
  return API.post(`api/v1/admin/zipcodes/`, payload, token)
}

export const getOrders = (payload, token) => {
  return API.get(`api/v1/admin/orders/${payload}`, token)
}

export const getOrderDetails = (id, token) => {
  return API.get(`api/v1/admin/orders/${id}/`, token)
}

export const getFeedbacks = (payload, token) => {
  return API.get(`api/v1/admin/feedback/${payload}`, token)
}

export const replyFeedbacks = (payload, token) => {
  return API.post(`api/v1/admin/feedback-reply/`, payload, token)
}
