import './App.css'
import {Route, Routes, useNavigate} from 'react-router-dom'
import {createTheme, ThemeProvider} from '@mui/material/styles'

//ROUTES
import * as ROUTES from './constants/routes'

//CONTAINERS
import {
  Login,
  Dashboard,
  Users,
  Payments,
  FeedBack,
  Request,
  RequestDetails,
} from './containers'
import AppContext from './Context'
import {
  getAllUsers,
  getDashboard,
  getDashboardMeetings,
  getFeedbacks,
  getOrders,
  getPayments,
  getPaymentTotal,
} from './api/admin'
import {useState} from 'react'
import {SnackbarProvider} from 'notistack'

const theme = createTheme()
function App () {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [dashboard, setDashboard] = useState(null)
  const [meetingDashboard, setMeetingDashboard] = useState(null)
  const [allUsers, setAllUsers] = useState([])
  const [payments, setPayments] = useState([])
  const [paymentTotal, setPaymentTotal] = useState(0)
  const [orders, setOrders] = useState([])
  const [feedbacks, setFeedbacks] = useState([])

  const _getDashboard = async () => {
    try {
      const token = localStorage.getItem('token')
      if (!token) {
        navigate('/')
        return
      }
      const res = await getDashboard(token)
      const res1 = await getDashboardMeetings(token)
      setDashboard(res?.data)
      setMeetingDashboard(res1?.data)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getAllUsers = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getAllUsers('', token)
      setAllUsers(res?.data)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getPayments = async () => {
    try {
      const token = localStorage.getItem('token')
      const res1 = await getPaymentTotal(token)
      const res = await getPayments(token)
      setPayments(res?.data)
      setPaymentTotal(res1?.data?.total)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getOrders = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getOrders('', token)
      setOrders(res?.data)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getFeedbacks = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getFeedbacks('?limit=9999', token)
      setFeedbacks(res?.data?.results)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  return (
    <AppContext.Provider
      value={{
        setUser,
        user,
        dashboard,
        _getDashboard,
        _getAllUsers,
        allUsers,
        // _getPayments,
        // payments,
        // orders,
        // _getOrders,
        feedbacks,
        _getFeedbacks,
        // paymentTotal,
        // meetingDashboard,
      }}
    >
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path={ROUTES.MAIN} element={<Login />} />
            <Route path={ROUTES.DASHBOARD} element={<Users />} />
            {/*<Route path={ROUTES.ANALYTICS} element={<Dashboard />} />*/}
            {/*<Route path={ROUTES.PAYMENTS} element={<Payments />} />*/}
            <Route path={ROUTES.FEEDBACK} element={<FeedBack />} />
          </Routes>
        </ThemeProvider>
      </SnackbarProvider>
    </AppContext.Provider>
  )
}

export default App
