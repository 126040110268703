import * as React from 'react'
import {Grid, Container, Dialog, Paper} from '@mui/material'
import {Layout} from '../../components'
import AppTable from '../../components/AppTable'
import AppContext from '../../Context'
import {useContext} from 'react'
import {useState} from 'react'
import {useEffect} from 'react'
import {Bar} from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import moment from 'moment'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'package_name',
    numeric: false,
    disablePadding: false,
    label: 'Package',
  },
  {
    id: 'modified_at',
    numeric: false,
    disablePadding: false,
    label: 'Time',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
]

function PaymentsContent () {
  const {payments, _getPayments} = useContext(AppContext)
  const [state, setState] = useState({
    filteredList: payments,
    open: false,
    code: '',
    city: '',
    zipstate: '',
    status: false,
  })

  useEffect(() => {
    if (payments) {
      handleChange('filteredList', payments)
    }
  }, [payments])
  const {filteredList, open, code, city, zipstate, status} = state
  const handleChange = (name, value) => {
    setState(pre => ({...pre, [name]: value}))
  }

  const filtered = value => {
    if (value) {
      const re = new RegExp(value, 'i')
      var filtered = payments?.filter(entry =>
        Object.values(entry).some(
          val => typeof val === 'string' && val.match(re),
        ),
      )
      handleChange('filteredList', filtered)
    } else {
      handleChange('filteredList', payments)
    }
  }

  const handleClose = value => {
    handleChange('open', value)
  }

  const getAmount = () => {
    const list = {
      labels: [],
      values: [],
    }
    if (payments?.length) {
      for (let i = 0; i < payments?.length; i++) {
        const element = payments[i]
        if (element?.amount) {
          list.labels.push(moment(element?.modified_at).format('YYYY-MM-DD'))
          list.values.push(Number(element?.amount))
        }
      }
    }
    return list
  }

  console.log('getAmount', getAmount())

  return (
    <Layout>
      <Container maxWidth='lg' sx={{mt: 4, mb: 4}}>
        <Paper sx={{mt: 2, borderRadius: 4}}>
          <Bar
            options={options}
            data={{
              labels: getAmount().labels,
              datasets: [
                {
                  label: '30 days payments',
                  data: getAmount().values,
                  backgroundColor: '#7ED957',
                },
              ],
            }}
          />
          {payments && (
            <AppTable
              rows={filteredList}
              nowarning={true}
              filterByPackage={filtered}
              payment
              goto={() => handleClose(true)}
              headingLeft={'History'}
              headCells={headCells}
            />
          )}
        </Paper>
      </Container>
    </Layout>
  )
}

export default function Payments () {
  return <PaymentsContent />
}
