import React, {useState, useEffect} from 'react'
import {useContext} from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import {useNavigate} from 'react-router-dom'
import {loginUser} from '../../api/auth'
import BG from '../../assets/svg/BG.svg'
import {ReactComponent as LogoLogin} from '../../assets/svg/logo-white.svg'
import AppContext from '../../Context'
import './index.css'
import {CircularProgress} from '@mui/material'
import {useSnackbar} from 'notistack'

export default function Login () {
  const navigate = useNavigate()
  const {_getDashboard, setUser} = useContext(AppContext)
  const {enqueueSnackbar} = useSnackbar()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const user = localStorage.getItem('user')
      setUser(JSON.parse(user))
      navigate('/users')
      return
    }
  }, [])
  const handleSubmit = async event => {
    event.preventDefault()
    try {
      setLoading(true)
      const data = new FormData(event.currentTarget)
      const payload = {
        username: data.get('email'),
        password: data.get('password'),
      }
      const res = await loginUser(payload)
      localStorage.setItem('token', res?.data?.token)
      localStorage.setItem('user', JSON.stringify(res?.data?.user))
      enqueueSnackbar('Login successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
      setUser(res?.data?.user)
      _getDashboard()
      setLoading(false)
      navigate('/dashboard')
    } catch (error) {
      setLoading(false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(${BG})`,
        width: '100%',
        minHeight: '100vh',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <LogoLogin className='logologin' />
          <Typography className='loginText' component='h1' variant='h5'>
            Login
          </Typography>
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              margin='normal'
              required
              fullWidth
              InputProps={{disableUnderline: true}}
              className='loginInput'
              variant='filled'
              id='email'
              size={'small'}
              placeholder='Email'
              name='email'
              autoComplete='email'
              autoFocus
            />
            <TextField
              variant='filled'
              margin='normal'
              required
              placeholder='Password'
              className='loginInput'
              size={'small'}
              InputProps={{disableUnderline: true}}
              fullWidth
              name='password'
              label=''
              type='password'
              id='password'
              autoComplete='current-password'
            />
            <Grid container style={{textAlign: 'right', marginTop: 10}}>
              <Grid item xs>
                <Link
                  href='#'
                  style={{color: '#fff', marginRight: 10}}
                  variant='body2'
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Button
              type='submit'
              fullWidth
              className='loginButton'
              variant='contained'
              sx={{mt: 3, mb: 2}}
            >
              {loading ? <CircularProgress className='loading' /> : 'Sign In'}
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  )
}
