import * as React from 'react'
import {styled} from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'
import BG from '../../assets/svg/BG.svg'
import {ReactComponent as Dashboard} from '../../assets/svg/dashboard.svg'
import {ReactComponent as Analytics} from '../../assets/svg/analytics.svg'
import {ReactComponent as Feedback} from '../../assets/svg/feedback.svg'
import {ReactComponent as Payment} from '../../assets/svg/payment.svg'
import {ReactComponent as Profilephoto} from '../../assets/svg/logo-white.svg'
import {useLocation, useNavigate} from 'react-router-dom'
import AppContext from '../../Context'
import {useContext} from 'react'
import {useEffect} from 'react'
import {Button, Popover} from '@mui/material'

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({theme, open}) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

function LayoutContent ({children}) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(true)
  const location = useLocation()
  const {
    user,
    setUser,
    _getDashboard,
    _getAllUsers,
    // _getPayments,
    // _getOrders,
    _getFeedbacks,
  } = useContext(AppContext)
  useEffect(() => {
    const userData = localStorage.getItem('user')
    setUser(JSON.parse(userData))
    _getDashboard()
    _getAllUsers()
    // _getPayments()
    // _getOrders()
    _getFeedbacks()
  }, [])

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    navigate('/')
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openPop = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const list = [
    {
      title: 'Users',
      route: '/dashboard',
      icon: <Dashboard className='iconSide' />,
    },
    {
      title: 'Feedback',
      route: '/feedback',
      icon: <Feedback className='iconSide' />,
    },
    // {
    //   title: 'Analytics',
    //   route: '/analytics',
    //   icon: <Analytics className='iconSide' />,
    // },
    // {
    //   title: 'Payments',
    //   route: '/payments',
    //   icon: <Payment className='iconSide' />,
    // },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundImage: `url(${BG})`,
        width: '100%',
        height: '100vh',
        top: 0,
        padding: '10px 20px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          display: 'flex',
          backgroundColor: 'rgba(255,255,255,0.8)',
          borderRadius: 10,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundSize: 'cover',
        }}
      >
        <Drawer
          variant='permanent'
          classes={{paper: 'drawerPaper'}}
          sx={{
            height: '95vh',
            backgroundColor: 'rgba(255,255,255,0.8)',
            borderRadius: 2,
          }}
          open={open}
        >
          <Toolbar
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              px: [1],
            }}
          >
          </Toolbar>
          {open && (
            <Grid container justifyContent={'center'}>
              {list.map((item, index) => (
                <Grid key={index} item xs={6}>
                  <div
                    className='cardSide'
                    onClick={() => navigate(item.route)}
                  >
                    <div className='blueBox'>{item.icon}</div>
                    <div className='boxtitle'>{item.title}</div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </Drawer>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            height: '95vh',
            overflow: 'auto',
          }}
        >
          <Grid container className='header' style={{background: "#0A3F74"}}>
            <div className='admindashboard' style={{ color: 'white' }}>Admin Dashboard</div>
            <div className='d-flex align-items-center'>
              <div className='welcometext'>
                <div style={{width: 'max-content', color: 'white'}}>Welcome back</div>
                <div style={{color: 'white'}}>Admin</div>
              </div>
              <Profilephoto onClick={handleClick} className='profilephoto' />
            </div>
            <Popover
              id={id}
              open={openPop}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Button sx={{p: 2}} onClick={logout}>
                Logout
              </Button>
            </Popover>
          </Grid>
          {children}
        </Box>
      </div>
    </Box>
  )
}

export default function Layout ({children}) {
  return <LayoutContent children={children} />
}
